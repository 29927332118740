<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            v-model="productsTable.filter.search"
            @input="debounceSearch"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getProducts.meta.page.total"
              :perPage="getProducts.meta.page.perPage"
              :currentPage="getProducts.meta.page.currentPage"
              @pagechanged="onPageChangeProducts"
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      @click="sortData('code')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      @click="sortData('name')"
                      scope="col"
                      class="cursor-pointer px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kelompok Produk
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody>
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getProducts.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detailBarang(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      {{ data.attributes.code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.attributes['product_category_name']
                          ? data.attributes['product_category_name']
                          : '-'
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Detail -->
    <product-detail-modal
      :visible="openDetail"
      :withAdditionalProductPrices="false"
      :withActiveToggle="false"
      @close="openDetail = false"
    >
      <template #attribute="{ product }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Kode Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.code }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nama Barang</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Isi Satuan</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.unit_name }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">P/N</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ !!product.data.attributes.is_point ? 'Point' : 'Non Point' }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">PV</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.point }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">BV</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ product.data.attributes.bonus | toCurrency }}
          </dd>
        </div>
      </template>
    </product-detail-modal>

    <loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import ProductDetailModal from '@/components/product/ProductDetailModal.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Barang',
  components: {
    Datatable,
    ProductDetailModal,
    BaseSearch,
  },
  data() {
    return {
      openDetail: false,
      sort: {
        name: 'createdAt',
        direction: 'desc',
      },
      productsTable: {
        filter: {
          search: null,
        },
        page: {
          size: 5,
          number: 1,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'products/getLoading',
      getProducts: 'products/getProducts',
    }),
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
      fetchProductById: 'products/fetchProductById',
    }),
    loadProducts() {
      this.fetchProducts({
        'filter[search]': this.productsTable.filter.search,
        'fields[products]': 'code,name,product_category_name',
        'page[size]': this.productsTable.page.size,
        'page[number]': this.productsTable.page.number,
        sortDirection: this.sort.direction,
        sortName: this.sort.name,
        include: '',
      });
    },
    resetProductPage() {
      this.productsTable.page.number = 1;
    },
    onPageChangeProducts(page) {
      this.productsTable.page.number = page;
      this.loadProducts();
    },
    sortData(sortName) {
      if (this.sort.name === sortName) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.name = sortName;
        this.sort.direction = 'asc';
      }

      this.loadProducts();
    },
    debounceSearch: debounce(function () {
      this.resetProductPage();
      this.loadProducts();
    }, 300),
    detailBarang(product) {
      this.fetchProductById({
        id: product.id,
        include: 'product-prices',
        fields: {
          // 'products': 'code,name,,is_point,point,bonus,unit_name,product-prices',
          'product-prices': 'price,area,buyer-type',
        },
      }).then(() => {
        this.openDetail = !this.openDetail;
      });
    },
  },
  created() {
    this.loadProducts();

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
