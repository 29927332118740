<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            placeholder="Cari Kode"
            v-model="search"
            v-on:input="onSearchStockist"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <StockistTable
              :with-promote="false"
              @page-change="onPageChangeStockist"
              @click-row="onClickDetailStockist"
              @promote="onOpenPromote"
            />
          </div>
        </div>

        <office-detail-modal
          v-model="openDetail"
          read-only
          @update="() => (openDetail = !openDetail)"
        />
      </div>
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import BaseSearch from '@/components/base/Search.vue';
import OfficeDetailModal from '@/components/office/office-detail-modal.vue';
import StockistTable from '@/components/stockist/StockistTable.vue';

export default {
  name: 'Home',
  components: {
    BaseSearch,
    OfficeDetailModal,
    StockistTable,
  },
  data() {
    return {
      modalType: '',
      selectedStockistToPromoteId: null,
      search: null,
      openDetail: false,
      promote: {
        code: null,
        email: null,
        no_account_gl: null,
        cost_center: null,
      },
      detailStockistId: null,
    };
  },
  created() {
    this.loadStockist();

    const handleEscape = () => {
      this.openDetail = false;
      this.search = '';
    };

    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getSimpleOffices: 'simple_offices/getSimpleOffices',
      getSimpleOfficesLoading: 'simple_offices/getLoading',
      getOfficesLoading: 'offices/getLoading',
    }),
    isLoading() {
      return this.getSimpleOfficesLoading || this.getOfficesLoading;
    },
  },
  methods: {
    ...mapActions({
      fetchUsersByOffice: 'users/fetchUsersByOffice',
      fetchOfficeById: 'offices/fetchOfficeById',
      promoteToSC: 'offices/promoteToSC',
      updateUser: 'users/updateUser',
      fetchSimpleOffices: 'simple_offices/fetchSimpleOffices',
    }),
    onSearchStockist: debounce(function () {
      this.loadStockist();
    }, 300),
    onPageChangeStockist(page) {
      this.loadStockist(page);
    },
    async onClickDetailStockist(office) {
      this.detailStockistId = office.attributes.office_id;

      await Promise.all([
        this.fetchOfficeById({
          office_id: this.detailStockistId,
          params: {
            include: 'addresses,area',
          },
        }),
        this.fetchUsersByOffice({
          officeId: this.detailStockistId,
        }),
      ]);

      this.openDetail = !this.openDetail;
    },
    onOpenPromote(data) {
      this.modalType = 'promoteToSC';
      this.isModalOpen = true;
      this.selectedStockistToPromoteId = data.attributes.office_id;
    },
    async loadStockist(params) {
      await this.fetchSimpleOffices({
        ...params,
        'fields[simple-offices]': 'code,name,office,office_id',
        'fields[offices]':
          'bank_account_number,phone,address,email,is_promoted,promoted_by',
        include: 'office',
        'filter[search]': this.search,
        'filter[office_category_id]': 4,
        'page[limit]': 5,
      });
    },
  },
};
</script>
