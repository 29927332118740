<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search
            v-model="officeExpeditionParams.filter.search"
            @input="onDebounceSearch"
            placeholder="Cari kode atau nama"
          />
          <div>
            <base-button id="add-supplier" @click="onAdd" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              Tambah Expedisi
            </base-button>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <office-expedition-table
              @edit="onEdit"
              @refresh="onRefresh"
              @change-page="onPageChange"
            />
          </div>
        </div>

        <!-- Modal Tambah Ekspedisi -->
        <office-expedition-form
          :visible="officeExpeditionFormProps.visible"
          @close="officeExpeditionFormProps.visible = false"
          @success="onSuccessForm"
          :expedition="officeExpeditionFormProps.expedition"
        />

        <transition name="fade">
          <div
            v-if="success"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            id="modal_success"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                  >
                    <svg
                      class="h-6 w-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading"> Data Berhasil disimpan </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="success = false"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Tambah Lagi
                    </button>
                    <button
                      @click="success = false"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div
            v-if="failed"
            class="fixed inset-0 z-10 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            id="modal_error"
          >
            <div
              class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
            >
              <div
                class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                class="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
                >&#8203;</span
              >
              <div
                class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-red-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <slot name="heading">
                      {{ getError }}
                    </slot>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <div class="flex items-center gap-2">
                    <button
                      @click.prevent="failed = false"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Ulangi
                    </button>
                    <button
                      @click="failed = false"
                      class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-yellow-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Selesai
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <loading v-if="getLoading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import OfficeExpeditionTable from '@/components/office-expedition/office-expedition-table.vue';
import OfficeExpeditionForm from '@/components/office-expedition/office-expedition-form.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Ekspedisi',
  components: {
    OfficeExpeditionTable,
    OfficeExpeditionForm,
    BaseSearch,
  },
  data() {
    return {
      officeExpeditionFormProps: {
        visible: false,
        expedition: null,
      },
      search: null,
      success: false,
      failed: false,
      officeExpeditionParams: {
        page: {
          size: 5,
          number: 1,
        },
        filter: {
          search: null,
        },
      },
    };
  },
  created() {
    this.loadOfficeExpeditions();
  },
  computed: {
    ...mapGetters({
      getError: 'office_expeditions/getError',
      getLoading: 'office_expeditions/getLoading',
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    ...mapActions({
      fetchOfficeExpeditionsByOffice:
        'office_expeditions/fetchOfficeExpeditionsByOffice',
    }),
    loadOfficeExpeditions() {
      this.fetchOfficeExpeditionsByOffice({
        office_id: this.getUser.office_id,
        params: {
          'page[size]': this.officeExpeditionParams.page.size,
          'page[number]': this.officeExpeditionParams.page.number,
          'filter[search]': this.officeExpeditionParams.filter.search,
        },
      });
    },
    onDebounceSearch: debounce(function () {
      this.officeExpeditionParams.page.number = 1;

      this.loadOfficeExpeditions();
    }, 300),
    onPageChange(page) {
      this.officeExpeditionParams.page.number = page;

      this.loadOfficeExpeditions();
    },
    onSuccessForm() {
      this.officeExpeditionParams.page.number = 1;
      this.loadOfficeExpeditions();

      this.success = true;
      this.officeExpeditionFormProps.visible = false;
    },
    onAdd() {
      this.officeExpeditionFormProps.expedition = null;
      this.officeExpeditionFormProps.visible = true;
    },
    onEdit(expedition) {
      this.officeExpeditionFormProps.expedition = expedition;
      this.officeExpeditionFormProps.visible = true;
    },
    onRefresh() {
      this.officeExpeditionParams.page.number = 1;

      this.loadOfficeExpeditions();
    },
  },
};
</script>
