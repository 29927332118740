<template>
  <div>
    <Datatable
      :total="getOfficeExpeditions.meta.page.total"
      :perPage="getOfficeExpeditions.meta.page.perPage"
      :currentPage="getOfficeExpeditions.meta.page.currentPage"
      @pagechanged="onPageChange"
    >
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Kode Expedisi
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Nama Expedisi
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Keterangan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Aksi
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="getOfficeExpeditions.data?.length > 0">
          <tr
            class="bg-white hover:bg-green-100"
            v-for="(data, dataIdx) in getOfficeExpeditions.data"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          >
            <td
              @click="onClickDetail(data)"
              class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
            >
              {{ data.attributes.code }}
            </td>
            <td
              @click="onClickDetail(data)"
              class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            >
              {{ data.attributes.name }}
            </td>
            <td
              @click="onClickDetail(data)"
              class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            >
              {{ data.attributes.description }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <div class="flex space-x-2">
                <button @click="onClickEdit(data)" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-yellow-400 hover:text-yellow-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                </button>
                <button @click="onClickRemove(data)" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-red-400 hover:text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="bg-white">
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              colspan="4"
            >
              Data tidak ditemukan
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>

    <Modal
      :showing="detailModal.visible"
      @close="detailModal.visible = false"
      :showClose="true"
      :backgroundClose="true"
      size="max-w-4xl"
    >
      <template v-if="detailModal.visible">
        <div class="space-y-6">
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Detail Ekspedisi
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Berdasarkan data ekspedisi yang telah dibuat
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-32" />
            </div>
          </div>
          <div class="border-t border-gray-200 pt-6">
            <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Kode Ekspedisi</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.code }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Nama Ekspedisi</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.name }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Keterangan</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.description }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Alamat 1</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.address_1 }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Alamat 2</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.address_2 }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">Dibuat</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.createdAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-3">
                <dt class="text-sm font-bold text-gray-700">
                  Terakhir Diperbarui
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ detailModal.expedition.attributes.updatedAt | formatDate }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="flex justify-end">
            <button
              @click="detailModal.visible = false"
              type="button"
              class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Tutup
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable';
import Modal from '@/components/base/BaseModal';

export default {
  name: 'OfficeExpeditionTable',
  components: { Datatable, Modal },
  emits: ['change-page', 'edit', 'refresh', 'error'],
  data() {
    return {
      detailModal: {
        visible: false,
        expedition: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOfficeExpeditions: 'office_expeditions/getOfficeExpeditions',
    }),
  },
  methods: {
    ...mapActions({
      updateOfficeExpedition: 'office_expeditions/updateOfficeExpedition',
    }),
    onPageChange(page) {
      this.$emit('change-page', page);
    },
    onClickEdit(item) {
      this.$emit('edit', item);
    },
    onClickRemove(item) {
      const payload = {
        data: {
          type: 'office-expeditions',
          id: item.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updateOfficeExpedition(payload).then((response) => {
        if (response) {
          this.$emit('refresh');
        } else {
          this.$emit('error');
        }
      });
    },
    onClickDetail(expedition) {
      this.detailModal.expedition = expedition;
      this.detailModal.visible = true;
    },
  },
};
</script>
